import { AppDispatch } from "./../../store";
import { getFiscalYear, getProfile } from "./../../../api/auth";
import * as authActions from "./authActions";
import axiosInstance from "../../../axios/axios";

export const checkLogin = (isAuth: boolean) => {
  // some aync logic
  return {
    type: authActions.CHECK_TOKEN,
    isAuth: isAuth,
  };
};

export const getProfileAction =
  (cb: Function) => async (dispatch: AppDispatch) => {
    const [response, error] = await getProfile();
    if (response) {
      dispatch({ type: authActions.GET_PROFILE, payload: response.data });
      cb();
    }
    if (error) {
      cb(error);
    }
  };

export const getFiscalYearAction =
  (cb: Function) => async (dispatch: AppDispatch) => {
    const [response, error] = await getFiscalYear();
    if (response) {
      dispatch({
        type: authActions.GET_FISCAL_YEAR,
        payload: response?.data?.fiscal_year || [],
      });
      cb();
    }
    if (error) {
      cb(error);
    }
  };

export const setFiscalYearAction =
  (cb: Function, payload: any) => async (dispatch: AppDispatch) => {
      dispatch({
        type: authActions.SET_FISCAL_YEAR,
        payload: payload,
      })
      cb();
    if (!payload) {
      cb(payload);
    }
  };

export const setProductAction =
(cb: Function, payload: any) => async (dispatch: AppDispatch) => {
    dispatch({
      type: authActions.SET_PRODUCT,
      payload: payload,
    })
    cb();
  if (!payload) {
    cb(payload);
  }
};

export const validateToken = async (token: string): Promise<[any, any]> => {
  try {
    await axiosInstance.get(`/auth/channel-admin/validate-token/${token}`);
    return [true, null];
  } catch (error) {
    return [null, error];
  }
};

export const resetPassword = async (
  password: string,
  confirmPassword: string,
  token: string
): Promise<[any, any]> => {
  try {
    await axiosInstance.post("/auth/channel-admin/reset-password", {
      token,
      password,
      confirmPassword,
    });
    return [true, null];
  } catch (error) {
    return [null, error];
  }
};
