import {
  // AccountBookOutlined,
  ContactsOutlined,
  DashboardOutlined,
  ReconciliationOutlined,
  // FileProtectOutlined,
  // SafetyCertificateOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { NavLinks } from "../interfaces";

export const sideNavList: NavLinks[] = [
  { href: "/", key: "/", icon: <DashboardOutlined />, title: "Dashboard" },
  // {
  //   href: "/certificates",
  //   icon: <AccountBookOutlined />,
  //   title: "Certificates",
  //   children: [
  //     {
  //       href: "/certificates/create",
  //       icon: <SafetyCertificateOutlined />,
  //       title: "Create certificate",
  //     },
  //     {
  //       href: "/certificates/my-certificates",
  //       icon: <FileProtectOutlined />,
  //       title: "My Certificates",
  //     },
  //   ],
  // },
  {
    href: "/subscriber",
    key: "/subscriber",
    icon: <UsergroupAddOutlined />,
    title: "Subscriber",
  },
  { href: "/claim", key: "/claim", icon: <ContactsOutlined />, title: "Claim" },
  {
    href: "/finance",
    key: "/finance",
    icon: <ReconciliationOutlined />,
    title: "Finance",
  },
];
