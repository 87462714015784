import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { NavLinks } from "../interfaces";
import { sideNavList } from "./sideNavList";
interface IProps {
  sidebarColllapse: boolean;
}

const { SubMenu } = Menu;

function Sidenav({ sidebarColllapse }: IProps) {
  const location = useLocation();
  const [menuKeys, setMenuKeys] = useState({
    selectedKey: location.pathname,
    openKey: "/" + location.pathname.split("/")[1],
  });
  const history = useHistory();

  useEffect(() => {
    setMenuKeys((keys) => ({
      ...keys,
      selectedKey: location.pathname.split("/").slice(0, 3).join("/"),
    }));
  }, [location]);

  return (
    <div>
      <Sider trigger={null} collapsible collapsed={sidebarColllapse}>
        <Menu
          mode={sidebarColllapse ? "vertical" : "inline"}
          selectedKeys={[menuKeys.selectedKey]}
        >
          {sideNavList.map((navLink: NavLinks) => {
            // if (navLink.children) {
            //   return (
            //     <SubMenu
            //       icon={navLink.icon}
            //       key={navLink.key}
            //       title={navLink.title}
            //     >
            //       {navLink.children.map((childrenLink: NavLinks) => (
            //         <Menu.Item key={childrenLink.key} icon={childrenLink.icon}>
            //           <Link to={childrenLink.href}>{childrenLink.title}</Link>
            //         </Menu.Item>
            //       ))}
            //     </SubMenu>
            //   );
            // }
            return (
              <Menu.Item
                key={navLink.key}
                icon={navLink.icon}
                onClick={() => history.push(navLink.href)}
              >
                {navLink.title}
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
    </div>
  );
}

export default Sidenav;
