import { ReactNode } from "react";
import { Route } from "react-router";
import { IRouteItem } from "../interfaces";
import { lazy } from "./lazy";

export const mainRoutesList: IRouteItem[] = [
  {
    name: "Dashboard",
    path: "/",
    LazyComponent: lazy(() => import("../pages/Home/Home")),
    exact: true,
  },
  {
    name: "Subscriber",
    path: "/subscriber",
    LazyComponent: lazy(() => import("../pages/Subscriber/subscriber")),
    exact: true,
  },
  {
    name: "Claim",
    path: "/claim",
    LazyComponent: lazy(() => import("../pages/Claim/Claim")),
    exact: true,
  },
  {
    name: "Financial Reconcilation",
    path: "/finance",
    LazyComponent: lazy(() => import("../pages/Financial/Financial")),
    exact: true,
  },
  // {
  //   name: "Create certificate",
  //   path: "/certificates/create",
  //   LazyComponent: lazy(() => import("../pages/Certificate/Certificate")),
  //   exact: true,
  // },
  // {
  //   name: "My certificates",
  //   path: "/certificates/my-certificates",
  //   LazyComponent: lazy(() => import("../pages/Certificate/MyCertificate")),
  //   exact: true,
  // },
  // {
  //   name: "My certificates",
  //   path: "/certificates/my-certificates/edit/:certificateId",
  //   LazyComponent: lazy(() => import("../pages/Certificate/Certificate")),
  //   exact: true,
  // },
  // {
  //   name: "Policy List",
  //   path: "/policy-list",
  //   LazyComponent: lazy(() => import("../pages/Policies/Policy")),
  //   exact: true,
  // },
];

const routerDom: ReactNode[] = [];
const parseRoutes = (routeList: IRouteItem[]) => {
  routeList.forEach((route, index) =>
    routerDom.push(
      <Route
        key={index + 1}
        {...route}
        children={(props) => <route.LazyComponent {...props} route={route} />}
      />
    )
  );
  return routerDom;
};

export const mainRoutes = parseRoutes(mainRoutesList);
