import { clearLocalStorage } from "./../util/localStorage.util";
import axios from "axios";

const baseURL = process.env.REACT_APP_MICRO_API;

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // const originalRequest = error.config;
    // Prevent infinite loops

    if (
      error?.response?.status === 401 &&
      window?.location?.pathname !== "/login"
    ) {
      clearLocalStorage();
      window.location.href = "/login";
      return Promise.reject(error);
    }

    // if (error.response.status === 401) {
    //   const refreshToken = getRefreshToken();

    //   if (refreshToken) {
    //     return axiosInstance
    //       .post("/auth/token", {
    //         refresh_token: refreshToken,
    //         grant_type: "refresh_token",
    //       })
    //       .then((response) => {
    //         setAuthToCookies(buildAuthResponse(response?.data));
    //         originalRequest.headers["Authorization"] =
    //           "Bearer " + buildAuthResponse(response?.data).accessToken;
    //         return axiosInstance(originalRequest);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   } else {
    //     console.log("Refresh token not available.");
    //     clearCookies();
    //     window.location.href = "/login/";
    //   }
    // }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);
export default axiosInstance;
