export const errorParser = (err: any) => {
  if (err?.response?.data?.error) {
    return err?.response?.data?.error?.message;
  } else {
    return "Something went wrong";
  }
};

export const numberWithCommas = (x: number | string) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const maxCharacterValidation = (_: any, value: any) => {
  if (!value || value.length < 255) {
    return Promise.resolve();
  }
  return Promise.reject("The field must contain less than 255 characters.");
};
