import { IAuthReducer } from "./../../interfaces/auth.interface";
import * as authActions from "../actions/auth/authActions";

const initState: IAuthReducer = {
  user: null,
  fiscalYearList: [],
  selectedFiscalYear: '',
  selectedProduct: '',
};

export const authReducer = (state = initState, action: any): IAuthReducer => {
  switch (action.type) {
    case authActions.GET_PROFILE: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case authActions.GET_FISCAL_YEAR: {
      return {
        ...state,
        fiscalYearList: action?.payload || [],
      };
    }
    case authActions.SET_FISCAL_YEAR: {
      return {
        ...state,
        selectedFiscalYear: action?.payload || [],
      };
    }
    case authActions.SET_PRODUCT: {
      return {
        ...state,
        selectedProduct: action?.payload || [],
      };
    }
    default: {
      return state;
    }
  }
};
